import React from 'react'
import styled from '@emotion/styled'
import { Text as CommonText } from '../common/typography'
import bp from '../common/breakpoints'
import { BLACK_COLOR_RGB, PRIMARY_COLOR } from '../common/color'

const Container = styled.div`
  display: grid;
  row-gap: 1rem;

  ${bp[1]} {
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    column-gap: 1rem;
  }
`

const Heading = styled.h6`
  margin: 1rem 0;
  font-weight: 700;
  font-size: 0.875rem;
  color: ${PRIMARY_COLOR};
`

const Text = styled(CommonText)`
  font-size: 0.875rem;
  color: ${BLACK_COLOR_RGB(0.6)};
`

const Objectives = ({ list }) => {
  return (
    <Container>
      {list.map((objective, index) => (
        <div key={index}>
          <Heading>{`Objective ${index + 1}`}</Heading>
          <Text>{objective}</Text>
        </div>
      ))}
    </Container>
  )
}

export default Objectives
