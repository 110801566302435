import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { HeadingContainer } from '../components/common/typography'
import CaseStudyView from '../components/case-studies/block'
import SEO from '../components/seo'
import AppLayout from '../components/layouts/appLayout'


const Container = styled.div`
  display: grid;
  row-gap: 2rem;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 6rem;
  box-sizing: border-box;
`

const ResourcesPage = ({ data }) => {
  const {
    allCaseStudies: { edges: caseStudies },
  } = data
  return (
    <AppLayout>
      <SEO title="Case Studies" canonical="case-studies" />
      <HeadingContainer
        title="You can always reach us"
        subtitle="Case Studies"
      />
      <Container> 
        {caseStudies.map(({ node: caseStudy }) => (
          <CaseStudyView frontmatter={caseStudy.frontmatter} />
        ))}
      </Container>
    </AppLayout>
  )
}

export const query = graphql`
  query {
    allCaseStudies: allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "case-studies" } } }
    ) {
      edges {
        node {
          id
          fields {
            sourceInstanceName
          }
          frontmatter {
            title
            description
            about
            order
            headline
            logo
            services {
              Year
              Timeline
              Sector
              Services
            }
            stack {
              devices
              frameworks
            }
            objectives
            challenge
            galleryImages {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          parent {
            ... on File {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default ResourcesPage
