import React from 'react'
import styled from '@emotion/styled'
import Gallery from './gallery'
import Objectives from './objectives'

import { Text } from '../common/typography'
import { BLACK_COLOR_RGB, PRIMARY_COLOR } from '../common/color'
import bp from '../common/breakpoints'
import NeritoSVG from '../../images/case-studies/nerito-logo.inline.svg'
import OperouteSVG from '../../images/case-studies/operoute-logo.inline.svg'
import TimelearningSVG from '../../images/case-studies/time4learning-logo.inline.svg'
import SalesforceSVG from '../../images/case-studies/salesforce-logo.inline.svg'

import AwsSVG from '../../images/icons/case-studies/aws.svg'
import VueSVG from '../../images/icons/case-studies/vue.svg'
import WordPressSVG from '../../images/icons/case-studies/wordpress.svg'
import DotNetSVG from '../../images/icons/case-studies/dotnet.svg'
import SqlSVG from '../../images/icons/case-studies/sql.svg'
import CsharpSVG from '../../images/icons/case-studies/csharp.svg'
import JsSVG from '../../images/icons/case-studies/javascript.svg'
import ReactSVG from '../../images/icons/case-studies/react.inline.svg'
import SocketioSVG from '../../images/icons/case-studies/socket-io.svg'
import AngularSVG from '../../images/icons/case-studies/angular.svg'
import MobileSVG from '../../images/icons/case-studies/phone.svg'
import DesktopSVG from '../../images/icons/case-studies/laptop.svg'
import DatabaseSVG from '../../images/icons/case-studies/database.svg'
import VisualforceSVG from '../../images/icons/case-studies/visualforce.svg'
import LightningSVG from '../../images/icons/case-studies/lightning.svg'
import ProcessflowbuilderSVG from '../../images/icons/case-studies/processflowbuilder.svg'
import TalendSVG from '../../images/icons/case-studies/talend.svg'

const logos = {
  nerito: <NeritoSVG />,
  operoute: <OperouteSVG />,
  timelearning: <TimelearningSVG />,
  salesforce: <SalesforceSVG />,
}

const frameworksSvgs = {
  aws: <AwsSVG />,
  js: <JsSVG />,
  react: <ReactSVG />,
  socketIo: <SocketioSVG />,
  angular: <AngularSVG />,
  vuejs: <VueSVG />,
  wordpress: <WordPressSVG />,
  dotnet: <DotNetSVG />,
  sql: <SqlSVG />,
  csharp: <CsharpSVG />,
  visualforce: <VisualforceSVG/>,
  lightning: <LightningSVG/>,
  processflowbuilder: <ProcessflowbuilderSVG/>,
  talend: <TalendSVG/>,
}

const devicesSvgs = {
  mobile: <MobileSVG />,
  desktop: <DesktopSVG />,
  database: <DatabaseSVG />,
}

const Content = styled.div`
  padding: 2rem 2rem 3rem;
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
  box-shadow: 10px 10px 20px ${BLACK_COLOR_RGB(0.1)};
  border-radius: 10px;
`

const CaseStudy = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;

  ${bp[1]} {
    grid-template-columns: 1fr 6fr;
    grid-template-rows: 60px auto auto 1fr auto auto;
    column-gap: 2rem;
    row-gap: 3rem;
  }
`

const IconContainer = styled.div`
  align-self: flex-end;
`

const Heading = styled.h2`
  position: relative;
  align-self: center;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${PRIMARY_COLOR};
  text-transform: uppercase;

  &::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 3rem;
    height: 3px;
    background-color: ${PRIMARY_COLOR};
  }
`
const CaseStudyTopic = styled.h4`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: ${BLACK_COLOR_RGB(0.8)};
`
const ContentSection = styled.div`
  color: ${BLACK_COLOR_RGB(0.7)};

  & > p {
    margin-bottom: 0.5rem;
  }
`

const ServicesBlock = styled.div`
  display: grid;
  row-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  align-items: flex-start;
  grid-row: 4/5;

  ${bp[1]} {
    grid-template-columns: unset;
    grid-row: 2/4;
    align-items: flex-start;
    justify-items: start;
  }
`
const ServiceItem = styled.div`
  display: grid;
  row-gap: 0.5rem;
`
const ServiceContent = styled.div`
  padding-left: 0.5rem;
  color: ${BLACK_COLOR_RGB(0.6)};
  font-size: 0.75rem;
`
const ServiceTitle = styled.h5`
  margin: 0;
  font-weight: 400;
  font-size: 0.825rem;
  color: ${PRIMARY_COLOR};
  text-transform: uppercase;
`

const GalleryBlock = styled.div`
  ${bp[1]} {
    grid-row: 3 / 5;
  }
`

const ObjectivesBlock = styled.div`
  ${bp[1]} {
    grid-column: 1 / 3;
  }
`

const StackContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-auto-flow: column;
  column-gap: 1rem;

  & > div svg {
    width: 40px;
    height: 40px;
  }

  ${bp[1]} {
    grid-auto-flow: row;
    row-gap: 0.5rem;
  }
`
const StackHeading = styled.h5`
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: ${PRIMARY_COLOR};
`
const DevicesContainer = styled.div`
  display: grid;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
`

const CaseStudyView = ({
  frontmatter: {
    logo,
    headline,
    about,
    services,
    galleryImages,
    title,
    stack: { frameworks, devices },
    challenge,
    objectives,
  },
}) => {
  return (
    <Content>
      <CaseStudy>
        <IconContainer>{logos[logo]}</IconContainer>
        <Heading>{headline}</Heading>
        <ServicesBlock>
          {Object.entries(services)
            .reverse()
            .map(([service, content]) => (
              <ServiceItem key={service}>
                <ServiceTitle>{service}</ServiceTitle>
                <ServiceContent>
                  {content.map((node) => (
                    <Text key={node}>{`- ${node}`}</Text>
                  ))}
                </ServiceContent>
              </ServiceItem>
            ))}
        </ServicesBlock>
        <ContentSection>
          <CaseStudyTopic>About</CaseStudyTopic>
          {about
            .split('\n')
            .filter(Boolean)
            .map((paragraph, index) => (
              <Text key={index}>{paragraph}</Text>
            ))}
        </ContentSection>
        <GalleryBlock>
          <CaseStudyTopic>Gallery</CaseStudyTopic>
          <Gallery images={galleryImages} projectTitle={title} />
        </GalleryBlock>
        <div>
          <StackHeading>Built On</StackHeading>
          <StackContainer>
            {frameworks.map((item) => (
              <div key={item}>{frameworksSvgs[item]}</div>
            ))}
          </StackContainer>
        </div>
        <ObjectivesBlock>
          <CaseStudyTopic>Objectives</CaseStudyTopic>
          <Objectives list={objectives} />
        </ObjectivesBlock>
        <DevicesContainer>
          {devices.map((item) => (
            <div key={item}>{devicesSvgs[item]}</div>
          ))}
        </DevicesContainer>
        <ContentSection>
          <CaseStudyTopic>Challenge</CaseStudyTopic>
          <Text>{challenge}</Text>
        </ContentSection>
      </CaseStudy>
    </Content>
  )
}

export default CaseStudyView
