import React, { useState, useRef, useEffect } from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import { ButtonNoStyle } from '../common/button'
import { WHITE_COLOR } from '../common/color'
import bp from '../common/breakpoints'

import ChevronRight from '../../images/icons/chevron-right.svg'
import ChevronLeft from '../../images/icons/chevron-left.svg'
import CloseSvg from '../../images/icons/cancel.svg'

const ImageGalleryContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-template-rows: 1fr 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`

const ImageButton = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;

  outline: none;
  cursor: pointer;
`

const Image = styled(Img)`
  max-width: ${(props) => (props.isShowcase ? 'unset' : '420px')};
  height: auto;
  margin: 0 auto;
`

const Counter = styled.div`
  justify-self: center;
  grid-column: 1 / -1;
  color: ${(props) => (props.isShowcase ? WHITE_COLOR : 'initial')};
`

const ArrowButton = styled(ButtonNoStyle)`
  padding: 0;

  & > svg {
    width: 30px;
    height: 30px;
  }
`

const ImageGallery = ({
  images,
  projectTitle,
  isShowcase = false,
  setShowCase = () => null,
}) => {
  const [currentImage, setCurrentImage] = useState(0)
  const showcaseRef = useRef(null)

  const handleClick = (type) => {
    const newCurrent = type === 'next' ? currentImage + 1 : currentImage - 1
    if (newCurrent >= 0 && newCurrent <= images.length - 1) {
      setCurrentImage(newCurrent)
    } else {
      setCurrentImage(type === 'next' ? 0 : images.length - 1)
    }
  }

  useEffect(() => {
    if (!isShowcase || !showcaseRef) {
      return
    }

    const closeShowCaseHandler = (event) => {
      if (!showcaseRef.current.contains(event.target)) {
        setShowCase(false)
      }
    }

    document.addEventListener('click', closeShowCaseHandler)

    return () => {
      document.removeEventListener('click', closeShowCaseHandler)
    }
  }, [setShowCase, isShowcase, showcaseRef])

  return (
    <ImageGalleryContainer ref={showcaseRef}>
      <ArrowButton onClick={() => handleClick('prev')}>
        <ChevronLeft />
      </ArrowButton>
      <ImageButton type="button" onClick={() => setShowCase(true)}>
        <Image
          isShowcase={isShowcase}
          fluid={images[currentImage].childImageSharp.fluid}
          alt={`${projectTitle} - Gallery Image`}
        />
      </ImageButton>
      <ArrowButton onClick={() => handleClick('next')}>
        <ChevronRight />
      </ArrowButton>
      <Counter isShowcase={isShowcase}>{`${currentImage + 1}/${
        images.length
      }`}</Counter>
    </ImageGalleryContainer>
  )
}

const Container = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto;
`

const ShowcaseContainer = styled.div`
  display: ${(props) => (props.isvisible ? 'grid' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  align-items: center;
  justify-content: center;
`

const ShowCaseImage = styled.div`
  min-width: 100vw;

  ${bp[1]} {
    min-width: 500px;
  }

  ${bp[2]} {
    min-width: 800px;
  }
`

const CloseShowCaseButton = styled(ButtonNoStyle)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
`

const GalleryBlock = ({ images, projectTitle }) => {
  const [showCase, setShowCase] = useState(false)

  return (
    <Container>
      <ImageGallery
        images={images}
        projectTitle={projectTitle}
        setShowCase={setShowCase}
      />
      <ShowcaseContainer isvisible={showCase}>
        <CloseShowCaseButton onClick={() => setShowCase(false)}>
          <CloseSvg />
        </CloseShowCaseButton>
        <ShowCaseImage>
          <ImageGallery
            images={images}
            projectTitle={projectTitle}
            isShowcase={showCase}
            setShowCase={setShowCase}
          />
        </ShowCaseImage>
      </ShowcaseContainer>
    </Container>
  )
}

export default GalleryBlock
